import { IEntityFilters } from '../../components/filters';

export const performanceFilters: IEntityFilters = {
	numberFilters: {
		clicks: { columnName: 'Monetized Clicks', cellKey: 'clicks' },
		sales: { columnName: 'Sales', cellKey: 'sales' },
		conversionsRate: { columnName: 'CR', cellKey: 'conversionsRate', isPercentage: true },
		revenue: { columnName: 'Revenue', cellKey: 'revenue' },
		epc: { columnName: 'EPC', cellKey: 'epc', isPercentage: true },
	},

	stringFilters: {},

	enumFilters: {},
};
