import dayjs from 'dayjs';
import { useDates } from '@monorepo/controlled/src/hooks/use-dates';
import { isStringInfinityOrNaN, number } from '@monorepo/tools/src/lib/utils/number';
import { useMemo } from 'react';
import { DecimalData, PerformanceEnumarableLabels } from '@monorepo/tools/src/lib/enums/performance-labels';
import { ReportModel } from '../../models/report.model';
import { IPublisherPerformance } from '../../models/performance.model';

// TODO - merge with adminx
type TMetrics = { [key: string]: number[] };

export const availablePerformanceToShow: { [key: string]: string } = {
	[PerformanceEnumarableLabels.Clicks]: 'clicks',
	[PerformanceEnumarableLabels.Revenue]: 'revenue',
	[PerformanceEnumarableLabels.Sales]: 'sales',
	[PerformanceEnumarableLabels.EPC]: 'epc',
	[PerformanceEnumarableLabels.CR]: 'conversionsRate',
};

export const useMetrics = (performanceContent: ReportModel<IPublisherPerformance> | null) => {
	const { startDate, endDate } = useDates();
	const performanceItems = performanceContent?.getData() || [];
	const performanceSummary = performanceContent?.getSummary() || {};
	let currentDate = dayjs(startDate);

	const generateMetrics = (items: IPublisherPerformance[], summary: IPublisherPerformance) => {
		const multipleMetrics: TMetrics = {};
		const _xLabels: string[] = [];

		const availablePerformanceSlugs = Object.values(availablePerformanceToShow);
		// loop by date and build the multipleMetrics object
		// the multiple metrics object will be object of arrays, each array will be array of numbers that will be displayed in the chart, each array will be one metric of availablePerformanceToMetrics
		while (currentDate.isBefore(dayjs(endDate)) || currentDate.isSame(dayjs(endDate))) {
			_xLabels.push(currentDate.format('MMM D, YYYY'));
			if (items.length > 0) {
				const currentPerformanceDay = items.find(
					pItem => dayjs(pItem.date).format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')
				);
				if (currentPerformanceDay) {
					// Set all available metrics in multipleMetrics
					Object.entries(currentPerformanceDay).forEach(([performanceName, performanceValue]) => {
						if (isStringInfinityOrNaN(performanceValue)) {
							performanceValue = 0;
						}

						if (availablePerformanceSlugs.includes(performanceName)) {
							if (!multipleMetrics[performanceName]) {
								multipleMetrics[performanceName] = [performanceValue];
							} else {
								multipleMetrics[performanceName].push(performanceValue);
							}
						}
					});
				} else {
					Object.keys(summary).forEach(key => {
						if (!multipleMetrics[key]) {
							multipleMetrics[key] = [0];
						} else {
							multipleMetrics[key].push(0);
						}
					});
				}
			}

			currentDate = dayjs(currentDate).add(1, 'day');
		}

		const _metrics = Object.entries(availablePerformanceToShow).map(([performanceLabel, performanceSlug]) => {
			const isClicks = performanceSlug === availablePerformanceToShow[PerformanceEnumarableLabels.Clicks];
			const isRevenue = performanceSlug === availablePerformanceToShow[PerformanceEnumarableLabels.Revenue];

			const visible =
				isClicks ||
				isRevenue ||
				performanceSlug === availablePerformanceToShow[PerformanceEnumarableLabels.Sales] ||
				performanceSlug === availablePerformanceToShow[PerformanceEnumarableLabels.EPC] ||
				performanceSlug === availablePerformanceToShow[PerformanceEnumarableLabels.CR];

			return {
				label: isClicks ? 'Monetized Clicks' : (performanceLabel as string),
				dataset: {
					data: multipleMetrics[performanceSlug],
					label: isClicks ? 'Monetized Clicks' : (performanceLabel as string),
				},
				sum: summary[performanceSlug as keyof IPublisherPerformance] as number,
				formatter: (val?: number) => number(val, DecimalData.includes(performanceLabel)) ?? 'N/A',
				selected: isClicks || isRevenue,
				visible,
			};
		});

		return {
			metrics: _metrics,
			xLabels: _xLabels,
			legends: Object.keys(availablePerformanceToShow),
		};
	};

	const { metrics, xLabels, legends } = useMemo(
		() => generateMetrics(performanceItems, performanceSummary as IPublisherPerformance),
		[performanceItems, performanceSummary]
	);

	return { metrics, xLabels, legends };
};
